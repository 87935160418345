<template lang="pug">
  Block(
    type="content",
    :icon="type.icon",
    :id="id",
    :has_customisations="has_customisations"
    :collections="collections",
    :show-controls="showControls"
    :searchable="searchable"
  )
    div.content-item-content.flex-grow
      header.flex.justify-between.space-x-6
        h2.ag-block__title: a(:href="content_url", target="_blank", v-html="$markdown(blockTitle)")
        div(v-if="showContentStatusSwitcher")
          ContentStatusSwitcher(:id="id", :status="status", size="small")
      main
        p.content-item-description(v-html="$markdown(description)")
      footer
        div.content-item-meta
          div.content-item-meta-item
            div.content-item-meta-icon
              Icon(:icon="type.icon")
            div
              span {{ type.name }}
          div.content-item-meta-item
            div.content-item-meta-icon
              Icon(:icon="depth.icon")
            div
              span {{ depth.name }}
          div.content-item-meta-item
            div.content-item-meta-icon
              Icon(icon="subjectArea")
            div
              ul.list-comma-separated: li(v-for="(subjectArea, i) in tags", :key="i") {{ subjectArea.tag }}
</template>

<script>
import { ContentStatusSwitcher } from "@syntax51/ag-shared-assets"
import Block from "./Block.vue"

export default {
  components: {
    Block,
    ContentStatusSwitcher
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    lead_in: {
      type: String,
      required: true
    },
    synopsis: {
      type: String,
      required: true
    },
    author: {
      type: String
    },
    runtime: {
      type: Number
    },
    content_url: {
      type: String,
      required: true
    },
    depth: {
      type: Object,
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    tags: {
      type: Array,
      default: () => []
    },
    customisations: {
      type: Object,
      default: () => {}
    },
    searchable: {
      type: Boolean,
      required: true
    },
    has_customisations: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Array,
      default: () => []
    },
    status: String,
    showControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    blockTitle() {
      return this.customisations?.title ?? this.title
    },
    description() {
      const description = []
      if (this.lead_in) description.push(`**${this.lead_in}**`)
      if (this.synopsis) description.push(this.synopsis)
      if (this.author) description.push(`*By ${this.author}.*`)
      if (this.runtime) description.push(`*Runtime: ${this.runtime} min.*`)
      return description.join(" ")
    },
    showContentStatusSwitcher() {
      return this.$route.name === "content"
    }
  }
}
</script>

<style>
.ag-block--content {
  .ag-block__title {
    @apply mb-2;
  }
}

.content-item {
  @apply rounded-lg bg-white shadow;

  & + & {
    @apply mt-3;
  }

  &-type {
    @apply mr-6 mt-1 flex items-center justify-center rounded text-2xl text-primary-default;

    .fa-book-open {
      @apply text-xl;
    }
  }

  &-media {
    @apply w-full overflow-hidden rounded-tl-lg rounded-tr-lg bg-gray-100;
    @apply hidden;
    height: 175px;
  }

  &-meta {
    @apply -mx-4 mt-6;

    &-item {
      @apply mt-3 flex items-center px-4;
    }

    &-icon {
      @apply mr-3 flex flex-shrink-0 items-center justify-center text-primary-default;
    }
  }

  &-controls {
    @apply rounded-bl-lg rounded-br-lg bg-gray-100 p-6;

    &-inner {
      @apply flex;
      margin-left: -5px;
      margin-right: -5px;
    }

    &-item {
      margin-left: 5px;
      margin-right: 5px;

      .button {
        @apply flex h-10 w-10 items-center justify-center rounded;

        &-prepend,
        &-content {
          @apply p-0;
        }

        &.secondary {
          @apply border-none;
        }
      }

      &-button--remove.button {
        @apply bg-secondary-default;
      }

      &--collections {
        @apply flex cursor-pointer items-center rounded bg-gray-300 py-1 pr-1;

        &__icon {
          @apply flex w-10 items-center justify-center text-supplement;
        }

        &__count {
          @apply flex h-full items-center rounded bg-gray-100 px-3 text-sm font-bold text-supplement;
        }

        &__popover {
          @apply hidden;
        }
      }

      &--extra {
        .dropdown {
          @apply block;

          &-trigger {
            @apply text-center;
          }
        }

        .icon {
          @apply text-lg font-bold;
        }
      }
    }
  }

  &.template-card {
    @apply flex flex-col;

    .content-item-body {
      @apply flex-grow;
    }
  }
}

@screen sm {
  .content-item {
    &-meta {
      @apply mt-2 flex flex-wrap;

      &-icon {
        @apply mr-3 w-auto;
      }
    }
  }
}

@screen md {
  .content-item {
    &.template-default {
      @apply flex;

      .content-item {
        &-body {
          @apply flex flex-grow p-10;
        }

        &-media {
          @apply mr-8 flex-shrink-0 rounded-md;
          height: 100px;
          width: 150px;
        }

        &-content {
          @apply p-0;
        }

        &-controls {
          @apply rounded-none rounded-br-md rounded-tr-md;

          &-inner {
            @apply -mt-3 flex-col;
          }

          &-item {
            @apply mt-3;

            .button {
              @apply rounded;
            }
          }
        }
      }
    }

    &.template-card {
      & + ^ & {
        @apply mt-0;
      }
    }

    &-controls-item--collections {
      @apply relative cursor-default flex-col justify-center pl-1 pt-0;

      &__icon {
        @apply h-10 w-8;
      }

      &__count {
        @apply h-auto w-full select-none justify-center px-0 py-1;
      }

      &__popover {
        @apply absolute top-0 z-10 overflow-hidden overflow-y-auto rounded border bg-white shadow;
        max-height: 500px;
        right: 100%;
        width: 350px;

        > p {
          @apply mx-6 my-4;
        }

        li {
          @apply border-t border-dotted;
        }

        a {
          @apply flex px-6 py-4;

          &:hover {
            @apply bg-primary-50;
          }
        }

        .collection-personal .icon {
          @apply text-supplement;
        }

        .icon {
          @apply mr-3 mt-1;
        }
      }

      &:hover {
        ^ &__popover {
          @apply block;
        }
      }
    }
  }
}
</style>
